html, body, #root {
    height: 100%
}

.MuiLink-root {
    cursor: pointer;
}

.ql-editor { height: 100px }

.ticketActionText p {
    margin: 0;
}

.ticket-signature-canvas {
    width: 100%;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.23);
    height: 200px;
}
